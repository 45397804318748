import React, { useRef } from "react";
import classes from "./index.module.scss";
import { useAnimation, useInView, motion } from "framer-motion";
import { colors } from "../../constants/colors";
import {
  consumerAppStoreUrl,
  consumerPlayStoreUrl,
  fadeInUpFixedVariants,
  merchantAppStoreUrl,
} from "../../pages/HomePage/constants";
import R from "../../res";
import { ArrowRightIcon } from "../Icons";
import { useWindowResize } from "../../hooks/useWindowResize";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { isSmallDevice } = useWindowResize();
  const navigate = useNavigate();
  const footerAnimation = useAnimation();
  const footerAnimationRef = useRef<HTMLDivElement>(null);
  const footerAnimationInView = useInView(footerAnimationRef, {
    once: true,
    amount: 0.5,
  });

  React.useEffect(() => {
    if (footerAnimationInView) {
      footerAnimation.start("fadeIn");
    } else {
      footerAnimation.start("fadeOut");
    }
  }, [footerAnimationInView]);

  const links = React.useMemo(
    () => [
      { icon: R.images.default.icTwitter, to: "https://twitter.com/herbanapp" },
      {
        icon: R.images.default.icFacebook,
        to: "https://facebook.com/herbanapp",
      },
      {
        icon: R.images.default.icInstagram,
        to: "https://www.instagram.com/herbanapp/",
      },
      // { icon: R.images.default.icReddit, to: "#" },
    ],
    []
  );

  return (
    <motion.div
      className={classes["container"]}
      animate={footerAnimation}
      variants={fadeInUpFixedVariants}
      ref={footerAnimationRef}
    >
      <div className={classes["content"]}>
        <div className={classes["grid"]}>
          <div>
            <img
              src={R.images.default.icLogo}
              alt="logo"
              className={classes["logo"]}
            />
            <div className={classes["social-media"]}>
              {links.map(({ icon, to }, index) => (
                <a
                  href={to}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={icon} alt={to} key={index} />
                </a>
              ))}
            </div>
          </div>
          <div>
            <p className={classes["title"]}>Contact</p>
            <p className={classes["label"]}>Email</p>
            <a
              href="mailto:hello@herban.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`${classes["row"]} ${classes["link-hover"]}`}>
                <p className={classes["value"]}>hello@herban.app</p>
                <ArrowRightIcon width={12} height={12} color={colors.gold} />
              </div>
            </a>
            <p className={classes["label"]}>Contact Form</p>
            <div className={`${classes["row"]} ${classes["link-hover"]}`}>
              <p className={classes["value"]}>HerbanApp Contact</p>
              <ArrowRightIcon width={12} height={12} color={colors.gold} />
            </div>
          </div>
          <div>
            <p className={classes["title"]}>Download Links</p>
            <p className={classes["label"]}>App Store</p>
            <a
              href={consumerAppStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`${classes["row"]} ${classes["link-hover"]}`}>
                <p className={classes["value"]}>HerbanApp</p>
                <ArrowRightIcon width={12} height={12} color={colors.gold} />
              </div>
            </a>
            <p className={classes["label"]}>Google Play</p>
            <a
              href={consumerPlayStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`${classes["row"]} ${classes["link-hover"]}`}>
                <p className={classes["value"]}>HerbanApp</p>
                <ArrowRightIcon width={12} height={12} color={colors.gold} />
              </div>
            </a>
          </div>
          <div>
            <p className={classes["title"]}>HerbanApp World</p>
            <p className={classes["label"]}>For Merchant's</p>
            <a
              href={merchantAppStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={`${classes["row"]} ${classes["link-hover"]}`}>
                <p className={classes["value"]}>HerbanApp Merchant</p>
                <ArrowRightIcon width={12} height={12} color={colors.gold} />
              </div>
            </a>
            {/* <p className={classes["label"]}>For Mobile Game Fans</p>
            <div className={`${classes["row"]} ${classes["link-hover"]}`}>
              <p className={classes["value"]}>Idle Weed</p>
              <ArrowRightIcon width={12} height={12} color={colors.gold} />
            </div> */}
            <p className={classes["label"]}>Blog</p>
            <div className={`${classes["row"]} ${classes["link-hover"]}`}>
              <p className={classes["value"]}>HerbanApp Blog</p>
              <ArrowRightIcon width={12} height={12} color={colors.gold} />
            </div>
          </div>
        </div>

        <div className={classes["bottom"]}>
          <p
            className={classes["copyright"]}
            // animate={footerAnimation}
            // variants={fadeInUpFixedVariants}
            // custom={0.3}
          >
            Copyright © 2022 HerbanApp
          </p>
          <div className={classes["links"]}>
            <button className={classes["link"]}>Cookies</button>
            <button
              className={classes["link"]}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </button>
            <button
              className={classes["link"]}
              onClick={() => navigate("/terms-of-service")}
            >
              Terms of Service
            </button>
          </div>
        </div>
      </div>
      <div className={classes["city-container"]}>
        <motion.div
          className={classes["images"]}
          initial={{
            translateX: "-100vw",
          }}
          animate={{
            translateX: "0vw",
            transition: {
              ease: "linear",
              repeat: Infinity,
              duration: isSmallDevice ? 1.5 : 6,
              repeatType: "loop",
            },
          }}
        >
          <img
            src={R.images.default.ilCityFooter}
            alt="illustration-city"
            className={classes["image"]}
            // style={{
            //   background: "blue",
            // }}
          />
          <img
            src={R.images.default.ilCityFooter}
            alt="illustration-city"
            className={classes["image"]}
            // style={{
            //   background: "red",
            // }}
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Footer;
