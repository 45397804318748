
    
    
    import gifAlien from './images/gif_alien.png';
import gifClapping from './images/gif_clapping.png';
import gifEyes from './images/gif_eyes.png';
import gifFaceExhaling from './images/gif_face_exhaling.png';
import gifFaceInCloudsGif from './images/gif_face_in_clouds.gif';
import gifGhost from './images/gif_ghost.png';
import gifHorns from './images/gif_horns.png';
import gifShushingGif from './images/gif_shushing.gif';
import gifSmirkingFace from './images/gif_smirking_face.png';
import icAppStore from './images/ic_app_store.png';
import icDownload from './images/ic_download.png';
import icEmail from './images/ic_email.png';
import icFacebook from './images/ic_facebook.png';
import icHerbanappWorld from './images/ic_herbanapp_world.png';
import icInstagram from './images/ic_instagram.png';
import icLogo from './images/ic_logo.png';
import icLogoFull from './images/ic_logo_full.png';
import icPlayStore from './images/ic_play_store.png';
import icReddit from './images/ic_reddit.png';
import icSocialMedia from './images/ic_social_media.png';
import icTwitter from './images/ic_twitter.png';
import ilAlien1 from './images/il_alien_1.png';
import ilAlien2 from './images/il_alien_2.png';
import ilCircle from './images/il_circle.svg';
import ilCity from './images/il_city.png';
import ilCityFooter from './images/il_city_footer.png';
import ilClap from './images/il_clap.png';
import ilCollision from './images/il_collision.png';
import ilDelivery from './images/il_delivery.png';
import ilDeliveryGuy from './images/il_delivery_guy.png';
import ilEmailSectionLeftIllustration from './images/il_email_section_left_illustration.png';
import ilEmailSectionRightIllustration from './images/il_email_section_right_illustration.png';
import ilEmoji1 from './images/il_emoji_1.png';
import ilEmoji2 from './images/il_emoji_2.png';
import ilEmoji3 from './images/il_emoji_3.png';
import ilEyes from './images/il_eyes.png';
import ilEyesFull from './images/il_eyes_full.png';
import ilFaceClouds from './images/il_face_clouds.png';
import ilFeature1 from './images/il_feature_1.png';
import ilFeature2 from './images/il_feature_2.png';
import ilFeature3 from './images/il_feature_3.png';
import ilFeatureShape from './images/il_feature_shape.png';
import ilFindDispensaries from './images/il_find_dispensaries.png';
import ilGhost from './images/il_ghost.png';
import ilHand1 from './images/il_hand_1.png';
import ilHand2 from './images/il_hand_2.png';
import ilHand3 from './images/il_hand_3.png';
import ilHand4 from './images/il_hand_4.png';
import ilHand5 from './images/il_hand_5.png';
import ilHand6 from './images/il_hand_6.png';
import ilHerbanapp from './images/il_herbanapp.png';
import ilHerbanappMobile from './images/il_herbanapp_mobile.png';
import ilIndica from './images/il_indica.png';
import ilLeaves from './images/il_leaves.png';
import ilLeftIllustration from './images/il_left_illustration.png';
import ilLeftWave1 from './images/il_left_wave_1.png';
import ilLeftWave2 from './images/il_left_wave_2.png';
import ilMascot from './images/il_mascot.png';
import ilMascot1 from './images/il_mascot_1.png';
import ilMascot2 from './images/il_mascot_2.png';
import ilRegisterDispensaries from './images/il_register_dispensaries.png';
import ilRightIllustration from './images/il_right_illustration.png';
import ilRightWave1 from './images/il_right_wave_1.png';
import ilRightWave2 from './images/il_right_wave_2.png';
import ilSativa from './images/il_sativa.png';
import ilScreenshot from './images/il_screenshot.png';
import ilSignHorns from './images/il_sign_horns.png';
import ilSkull from './images/il_skull.png';
import ilWaves from './images/il_waves.png';
import ilWavesMobile from './images/il_waves_mobile.png';
import ilYouWantTo from './images/il_you_want_to.png';
import ilYouWantToMobile from './images/il_you_want_to_mobile.png';
import plFeatures from './images/pl_features.png';

    const images = {
      dark: {},
      light: {},
      default: {gifAlien,gifClapping,gifEyes,gifFaceExhaling,gifFaceInClouds: gifFaceInCloudsGif,gifGhost,gifHorns,gifShushing: gifShushingGif,gifSmirkingFace,icAppStore,icDownload,icEmail,icFacebook,icHerbanappWorld,icInstagram,icLogo,icLogoFull,icPlayStore,icReddit,icSocialMedia,icTwitter,ilAlien1,ilAlien2,ilCircle,ilCity,ilCityFooter,ilClap,ilCollision,ilDelivery,ilDeliveryGuy,ilEmailSectionLeftIllustration,ilEmailSectionRightIllustration,ilEmoji1,ilEmoji2,ilEmoji3,ilEyes,ilEyesFull,ilFaceClouds,ilFeature1,ilFeature2,ilFeature3,ilFeatureShape,ilFindDispensaries,ilGhost,ilHand1,ilHand2,ilHand3,ilHand4,ilHand5,ilHand6,ilHerbanapp,ilHerbanappMobile,ilIndica,ilLeaves,ilLeftIllustration,ilLeftWave1,ilLeftWave2,ilMascot,ilMascot1,ilMascot2,ilRegisterDispensaries,ilRightIllustration,ilRightWave1,ilRightWave2,ilSativa,ilScreenshot,ilSignHorns,ilSkull,ilWaves,ilWavesMobile,ilYouWantTo,ilYouWantToMobile,plFeatures},
    };

    export default images;
    