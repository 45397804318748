import { Variants } from "framer-motion";
export const ease = "easeInOut" as const;

export const fadeInUpVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateY: "110%",
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeInLeftFixedVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateX: -100,
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeInDownVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateY: "-110%",
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeInLeftVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateX: "-50%",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeInLeftIllustrationVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateX: 0,
    rotateZ: "180deg",
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateX: "-50%",
    rotateZ: "180deg",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeInRightVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateX: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateX: "50%",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const fadeInUpFixedVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateY: 0,
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateY: 100,
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const textFadeInUpFixedVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateY: 0,
    translateX: "-50%",
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateY: 100,
    translateX: "-50%",
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeInVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 0.7,
      ease,
    },
  },
};

export const fadeInSlowVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 1,
    transition: {
      duration: 1,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 1,
      ease,
    },
  },
};

export const scaleInVariants: Variants = {
  scaleIn: (delay: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  scaleOut: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.7,
      ease,
    },
  },
};

export const scaleRotateInVariants: Variants = {
  scaleIn: (delay: number) => ({
    opacity: 1,
    scale: 1,
    rotate: "0deg",
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  scaleOut: {
    opacity: 0,
    scale: 0,
    rotate: "-90deg",
    transition: {
      duration: 0.7,
      ease,
    },
  },
};

export const consumerAppStoreUrl =
  "https://apps.apple.com/us/app/herbanapp-find-a-joint-nearby/id1579519606";

export const consumerPlayStoreUrl =
  "https://play.google.com/store/apps/details?id=app.herban.consumer";

export const merchantAppStoreUrl =
  "https://apps.apple.com/us/app/herbanapp-merchant/id1623356204";

export const merchantPlayStoreUrl =
  "https://play.google.com/store/apps/details?id=app.herban.merchant";
