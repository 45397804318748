import {
  motion,
  TargetAndTransition,
  useAnimation,
  // useInView,
  useScroll,
  useTransform,
  VariantLabels,
} from "framer-motion";
import React, { useRef } from "react";
import { ArrowRightIcon, MailIcon } from "../../components/Icons";
import { colors } from "../../constants/colors";
import { useWindowResize } from "../../hooks/useWindowResize";
import R from "../../res";
import classes from "./index.module.scss";
import wavesSvg from "../../res/svgs/waves.svg";
import { IntersectionOptions, useInView } from "react-intersection-observer";

// @ts-ignore
import mascotSafari from "../../res/videos/hb_mascot.mov";

// @ts-ignore
import mascotChrome from "../../res/videos/hb_mascot.webm";

// @ts-ignore
import liquidRightSafari from "../../res/videos/liquid_right.mov";

// @ts-ignore
import liquidRightChrome from "../../res/videos/liquid_right.webm";

// @ts-ignore
import leavesBackground from "../../res/videos/leaves_background.mp4";

// @ts-ignore
import deliverySafari from "../../res/videos/delivery.mov";

// @ts-ignore
import deliveryChrome from "../../res/videos/delivery.webm";

import {
  consumerAppStoreUrl,
  consumerPlayStoreUrl,
  fadeInDownVariants,
  fadeInLeftFixedVariants,
  fadeInLeftIllustrationVariants,
  fadeInLeftVariants,
  fadeInRightVariants,
  fadeInSlowVariants,
  fadeInUpFixedVariants,
  fadeInUpVariants,
  fadeInVariants,
  scaleInVariants,
  scaleRotateInVariants,
  textFadeInUpFixedVariants,
} from "./constants";
import Footer from "../../components/Footer";
import { checkBrowser } from "../../util/checkBrowser";
import LoadingView from "../../components/LoadingView";
import Lenis from "@studio-freight/lenis";

function scroll() {
  const lenis = new Lenis({
    duration: 2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    infinite: false,
    smoothWheel: true,
  });

  lenis.on("scroll", (e: any) => {
    // console.log(e);
  });

  function raf(time: DOMHighResTimeStamp) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
}

scroll();

const HomePage = () => {
  const { height, isSmallerDevice, isSmallDevice, width } = useWindowResize();
  const [isLoading, setIsLoading] = React.useState(true);

  const downloadSectionRef = useRef<HTMLDivElement>(null);

  const { scrollY, scrollYProgress } = useScroll();

  const isSafari = React.useMemo(
    () => checkBrowser().toLowerCase().includes("safari"),
    []
  );

  const headerBackgroundOpacity = useTransform(scrollY, [0, height], [0, 1]);

  const shapeRotate = useTransform(scrollYProgress, [0, 1], ["0deg", "360deg"]);

  const options: IntersectionOptions = React.useMemo(
    () => ({
      triggerOnce: true,
      initialInView: true,
      trackVisibility: true,
      delay: 100,
    }),
    []
  );

  const launchAnimation = useAnimation();
  const { ref: launchAnimationRef, inView: launchAnimationInView } = useInView({
    threshold: isSmallDevice ? 0.3 : 1,
    ...options,
  });

  React.useEffect(() => {
    if (launchAnimationInView) {
      launchAnimation.start("fadeIn");
    } else {
      launchAnimation.start("fadeOut");
    }
  }, [launchAnimationInView]);

  const featuresAnimation = useAnimation();

  const { ref: featuresAnimationRef, inView: featuresAnimationInView } =
    useInView({
      threshold: isSmallDevice ? 0.3 : 0.7,
      ...options,
    });

  React.useEffect(() => {
    preloadSomeAssets();
    // preloadAllAssets();
  }, []);

  const preloadSomeAssets = async () => {
    const safariVideos = [mascotSafari, liquidRightSafari];

    const chromeVideos = [mascotChrome, liquidRightChrome];

    const videos = [leavesBackground];

    if (isSafari) {
      videos.push(...safariVideos);
      for (const video of videos) {
        await new Promise((resolve) => {
          const vid = document.createElement("video");
          vid.src = video;
          vid.addEventListener("canplaythrough", () => {
            resolve(true);
          });
        });
      }
    } else {
      videos.push(...chromeVideos);
      for (const video of videos) {
        await new Promise((resolve) => {
          const vid = document.createElement("video");
          vid.src = video;
          vid.onloadeddata = () => {
            resolve(true);
          };
        });
      }
    }

    setIsLoading(false);
  };

  // const preloadAllAssets = async () => {
  //   const images = Object.values(R.images.default);
  //   for (const image of images) {
  //     await new Promise((resolve) => {
  //       const img = new Image();
  //       img.src = image;
  //       img.onload = () => {
  //         resolve(true);
  //       };
  //     });
  //   }

  //   const safariVideos = [mascotSafari, liquidRightSafari, deliverySafari];

  //   const chromeVideos = [mascotChrome, liquidRightChrome, deliveryChrome];

  //   const videos = [leavesBackground];

  //   if (isSafari) {
  //     videos.push(...safariVideos);
  //     for (const video of videos) {
  //       await new Promise((resolve) => {
  //         const vid = document.createElement("video");
  //         vid.src = video;
  //         vid.onloadeddata = () => {
  //           resolve(true);
  //         };
  //       });
  //     }
  //   } else {
  //     videos.push(...chromeVideos);
  //     for (const video of videos) {
  //       await new Promise((resolve) => {
  //         const vid = document.createElement("video");
  //         vid.src = video;
  //         vid.onloadeddata = () => {
  //           resolve(true);
  //         };
  //       });
  //     }
  //   }

  //   setIsLoading(false);
  // };

  React.useEffect(() => {
    if (featuresAnimationInView) {
      featuresAnimation.start("fadeIn");
    } else {
      featuresAnimation.start("fadeOut");
    }
  }, [featuresAnimationInView]);

  const emailSectionAnimation = useAnimation();

  const { ref: emailSectionAnimationRef, inView: emailSectionAnimationInView } =
    useInView({
      threshold: isSmallDevice ? 0.3 : 0.7,
      ...options,
    });

  React.useEffect(() => {
    if (emailSectionAnimationInView) {
      emailSectionAnimation.start("fadeIn");
    } else {
      emailSectionAnimation.start("fadeOut");
    }
  }, [emailSectionAnimationInView]);

  const howToAnimation = useAnimation();

  const { ref: howToScrollRef, inView: howToAnimationInView } = useInView({
    threshold: isSmallDevice ? 0.1 : 0.7,
    ...options,
  });

  React.useEffect(() => {
    if (howToAnimationInView) {
      howToAnimation.start("fadeIn");
      howToAnimation.start("scaleIn");
    } else {
      howToAnimation.start("fadeOut");
      howToAnimation.start("scaleOut");
    }
  }, [howToAnimationInView]);

  const youWantToAnimation = useAnimation();

  const { ref: youWantToAnimationRef, inView: youWantToAnimationInView } =
    useInView({
      threshold: isSmallDevice ? 0.3 : 0.8,
      ...options,
    });

  React.useEffect(() => {
    if (youWantToAnimationInView) {
      youWantToAnimation.start("animate");
    } else {
      youWantToAnimation.start("initial");
    }
  }, [youWantToAnimationInView]);

  const howTo = React.useMemo(
    () => [
      {
        image: R.images.default.ilRegisterDispensaries,
        label: "Register & enter your address",
        description:
          "Register on the HerbanApp consumer mobile app and insert your physical address to set up your account!",
      },
      {
        image: R.images.default.ilFindDispensaries,
        label: "Find the best dispensaries near you.",
        description:
          "We will find all the dispensaries near you and filter them in categories so you can find what you need.",
      },
      {
        image: R.images.default.ilDelivery,
        label: "Get weed delivered or order pick-up.",
        description:
          "Many dispensaries in HerbanApp will offer delivery & pickup options for your convience.",
      },
    ],
    []
  );

  const features = React.useMemo(
    () => [
      {
        image: R.images.default.ilFeature1,
        label: "chatting",
        description: "Talk With the Dispensaries",
      },
      {
        image: R.images.default.ilFeature2,
        label: "Live Tracking",
        description: "Track your orders  at all times",
      },
      {
        image: R.images.default.ilFeature3,
        label: "Discovery",
        description: "Find The best options in your city",
      },
    ],
    []
  );

  const shakeOnOver: TargetAndTransition | VariantLabels = React.useMemo(() => {
    return {
      rotate: [
        "-5deg",
        "5deg",
        "-5deg",
        "5deg",
        "-5deg",
        "5deg",
        "-5deg",
        "5deg",
        "0deg",
      ],
      transition: {
        ease: "easeInOut",
        duration: 0.9,
        // repeat: Infinity,
      },
    };
  }, []);

  // if (isLoading) return <LoadingView />;

  return (
    <>
      <div className={classes["header"]}>
        <motion.img
          src={R.images.default.icLogoFull}
          alt="logo"
          className={classes["logo"]}
          variants={fadeInUpVariants}
          initial="fadeOut"
          animate="fadeIn"
        />

        <motion.div
          className={classes["row"]}
          variants={fadeInUpVariants}
          initial="fadeOut"
          animate="fadeIn"
        >
          {/* <Link to="#" className={classes["link"]}>
              <p>Merchant</p>
            </Link> */}
          <motion.button
            className={classes["main-button"]}
            whileTap={{ scale: 0.97 }}
            onClick={() => {
              downloadSectionRef.current?.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <p>download app</p>
          </motion.button>
        </motion.div>
      </div>
      <motion.div
        className={classes["header-background"]}
        style={{
          opacity: headerBackgroundOpacity,
        }}
      />
      <div className={classes["banner"]}>
        <motion.video
          className={classes["left-illustration"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftIllustrationVariants}
          autoPlay
          loop
          muted
          controls={false}
          src={isSafari ? liquidRightSafari : liquidRightChrome}
        />
        <motion.video
          className={classes["right-illustration"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInRightVariants}
          autoPlay
          loop
          muted
          controls={false}
          src={isSafari ? liquidRightSafari : liquidRightChrome}
        />
        <motion.img
          src={R.images.default.ilMascot1}
          alt="mascot 1"
          className={classes["il-mascot-1"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.ilMascot2}
          alt="mascot 2"
          className={classes["il-mascot-2"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.gifAlien}
          alt="alien 1"
          className={classes["il-alien-1"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.gifAlien}
          alt="alien 2"
          className={classes["il-alien-2"]}
          initial="scaleOut"
          animate="scaleIn"
          variants={scaleInVariants}
          custom={0.5}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.gifSmirkingFace}
          alt="emoji 1"
          className={classes["il-emoji-1"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.ilCollision}
          alt="collision"
          className={classes["il-collision"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInRightVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.ilCircle}
          alt="circle 1"
          className={classes["il-circle-1"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
        />
        <motion.img
          src={R.images.default.gifClapping}
          alt="clap"
          className={classes["il-clap"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={fadeInRightVariants}
          whileHover={shakeOnOver}
        />
        <motion.video
          autoPlay
          loop
          muted
          controls={false}
          src={isSafari ? mascotSafari : mascotChrome}
          className={classes["il-mascot-video"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={textFadeInUpFixedVariants}
        ></motion.video>
        <motion.img
          src={
            isSmallerDevice
              ? R.images.default.ilHerbanappMobile
              : R.images.default.ilHerbanapp
          }
          alt="herbanapp"
          className={classes["il-herbanapp"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={textFadeInUpFixedVariants}
          custom={0.2}
        />
        <motion.img
          src={R.images.default.ilIndica}
          alt="indica"
          className={classes["il-indica"]}
          initial="scaleOut"
          animate="scaleIn"
          variants={scaleInVariants}
          custom={0.5}
        />
        <motion.img
          src={R.images.default.ilSativa}
          alt="sativa"
          className={classes["il-sativa"]}
          initial="scaleOut"
          animate="scaleIn"
          variants={scaleInVariants}
          custom={0.5}
        />
        <div className={classes["city-container"]}>
          <motion.div
            className={classes["images"]}
            initial={{
              translateX: "-100vw",
            }}
            animate={{
              translateX: "0vw",
              transition: {
                ease: "linear",
                repeat: Infinity,
                duration: isSmallDevice ? 1.5 : 6,
                repeatType: "loop",
              },
            }}
          >
            <img
              src={R.images.default.ilCity}
              alt="illustration-city"
              className={classes["image"]}
            />
            <img
              src={R.images.default.ilCity}
              alt="illustration-city"
              className={classes["image"]}
            />
          </motion.div>
        </div>
        <motion.button
          className={classes["button"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={textFadeInUpFixedVariants}
          custom={0.3}
          whileTap={{ scale: 0.97 }}
          onClick={() => {
            downloadSectionRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <p>Download app</p>
        </motion.button>
        <motion.p
          className={classes["label"]}
          initial="fadeOut"
          animate="fadeIn"
          variants={textFadeInUpFixedVariants}
          custom={0.1}
        >
          The Best Joints Near You
        </motion.p>
      </div>
      <div className={classes["section"]} ref={launchAnimationRef}>
        <motion.video
          className={classes["il-leaves"]}
          animate={launchAnimation}
          variants={fadeInSlowVariants}
          autoPlay
          loop
          muted
          controls={false}
          src={leavesBackground}
        />
        {/* <motion.img
            className={classes["il-leaves"]}
            animate={launchAnimation}
            variants={fadeInSlowVariants}
            src={R.images.default.ilLeaves}
          /> */}
        <motion.img
          className={classes["il-hands-1"]}
          src={R.images.default.ilHand1}
          animate={launchAnimation}
          variants={fadeInLeftVariants}
          custom={0.75}
        />
        <motion.img
          className={classes["il-hands-2"]}
          src={R.images.default.ilHand2}
          animate={launchAnimation}
          variants={fadeInUpVariants}
          custom={0.7}
        />
        <motion.img
          className={classes["il-hands-3"]}
          src={R.images.default.ilHand3}
          animate={launchAnimation}
          variants={fadeInUpVariants}
          custom={0.65}
        />
        <motion.img
          className={classes["il-hands-4"]}
          src={R.images.default.ilHand4}
          animate={launchAnimation}
          variants={fadeInRightVariants}
          custom={0.6}
        />
        <motion.img
          className={classes["il-hands-5"]}
          src={R.images.default.ilHand5}
          animate={launchAnimation}
          variants={fadeInDownVariants}
          custom={0.55}
        />
        <motion.img
          className={classes["il-hands-6"]}
          src={R.images.default.ilHand6}
          animate={launchAnimation}
          variants={fadeInDownVariants}
          custom={0.5}
        />
        <motion.p
          className={classes["label"]}
          animate={launchAnimation}
          variants={fadeInUpFixedVariants}
          custom={0.1}
        >
          launch in united states & canada
        </motion.p>
        <motion.p
          className={classes["title"]}
          variants={fadeInUpFixedVariants}
          animate={launchAnimation}
          custom={0.2}
        >
          download <span>herbanapp</span> on our soft launch in
          <span> usa & canada</span>
        </motion.p>
        <motion.button
          className={classes["main-button"]}
          variants={fadeInUpFixedVariants}
          animate={launchAnimation}
          custom={0.3}
          whileTap={{ scale: 0.97 }}
          onClick={() => {
            downloadSectionRef.current?.scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <p>Download App</p>
        </motion.button>
      </div>
      <div className={classes["how-to-section"]} ref={howToScrollRef}>
        <motion.video
          className={classes["left-illustration"]}
          animate={howToAnimation}
          custom={0.8}
          variants={fadeInLeftIllustrationVariants}
          autoPlay
          loop
          muted
          controls={false}
          src={isSafari ? liquidRightSafari : liquidRightChrome}
        />
        <motion.video
          className={classes["right-illustration"]}
          animate={howToAnimation}
          custom={0.8}
          variants={fadeInRightVariants}
          autoPlay
          loop
          muted
          controls={false}
          src={isSafari ? liquidRightSafari : liquidRightChrome}
        />
        <motion.img
          src={R.images.default.ilMascot1}
          alt="mascot 1"
          className={classes["il-mascot-1"]}
          animate={howToAnimation}
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
          custom={0.85}
        />
        <motion.img
          src={R.images.default.gifAlien}
          alt="alien 1"
          className={classes["il-alien-1"]}
          animate={howToAnimation}
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
          custom={0.9}
        />
        <motion.img
          src={R.images.default.gifSmirkingFace}
          alt="emoji 1"
          className={classes["il-emoji-1"]}
          animate={howToAnimation}
          variants={fadeInLeftVariants}
          whileHover={shakeOnOver}
          custom={0.95}
        />
        <motion.img
          src={R.images.default.ilCollision}
          alt="collision"
          className={classes["il-collision"]}
          animate={howToAnimation}
          variants={fadeInRightVariants}
          whileHover={shakeOnOver}
          custom={1}
        />
        <motion.img
          src={R.images.default.ilClap}
          alt="clap"
          className={classes["il-clap"]}
          animate={howToAnimation}
          variants={fadeInRightVariants}
          whileHover={shakeOnOver}
          custom={1.05}
        />
        <motion.p
          className={classes["how-to"]}
          variants={fadeInUpVariants}
          animate={howToAnimation}
          custom={0.3}
        >
          how to<span> order </span>on
        </motion.p>
        <div className={classes["row"]}>
          <motion.p
            className={classes["label-1"]}
            variants={fadeInUpVariants}
            animate={howToAnimation}
            custom={0.4}
          >
            herban
            <span>app</span>?
          </motion.p>
          <motion.p
            className={classes["label-2"]}
            variants={fadeInUpVariants}
            animate={howToAnimation}
            custom={0.5}
          >
            it's quiet <span>easy!</span>
          </motion.p>
        </div>
        <div className={classes["list"]}>
          {howTo.map(({ description, image, label }, index) => {
            return (
              <div key={index} className={classes["item"]}>
                <motion.div
                  className={classes["image"]}
                  animate={howToAnimation}
                  variants={scaleRotateInVariants}
                  custom={0.5 + index * 0.1}
                >
                  <motion.img
                    src={R.images.default.ilFeatureShape}
                    alt={"shape"}
                    className={classes["shape"]}
                    style={{
                      rotate: shapeRotate,
                    }}
                  />
                  <img
                    src={image}
                    alt={label}
                    className={classes["illustration"]}
                  />
                </motion.div>
                <motion.p
                  variants={fadeInUpFixedVariants}
                  animate={howToAnimation}
                  custom={0.5 + index * 0.1}
                  className={classes["index"]}
                >
                  0{index + 1}.
                </motion.p>
                <motion.p
                  variants={fadeInUpFixedVariants}
                  animate={howToAnimation}
                  custom={0.6 + index * 0.1}
                  className={classes["label"]}
                >
                  <span>{`${label.split(" ")[0]}`}</span>
                  {` ${label.split(" ").slice(1).join(" ")}`}
                </motion.p>
                <motion.p
                  variants={fadeInUpFixedVariants}
                  animate={howToAnimation}
                  custom={0.7 + index * 0.1}
                  className={classes["text"]}
                >
                  {description}
                </motion.p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes["features-section"]} ref={featuresAnimationRef}>
        <div className={classes["list"]}>
          {features.map(({ description, image, label }, index) => {
            return (
              <div key={index} className={classes["item"]}>
                <motion.img
                  src={image}
                  alt={"image"}
                  className={classes["image"]}
                  variants={fadeInUpFixedVariants}
                  animate={featuresAnimation}
                  custom={0.1 * index}
                />

                <motion.p
                  variants={fadeInUpFixedVariants}
                  animate={featuresAnimation}
                  className={classes["index"]}
                  custom={0.4 + 0.1 * index}
                >
                  {label}
                </motion.p>
                <div className={classes["row"]}>
                  <motion.div
                    className={classes["strip"]}
                    variants={fadeInRightVariants}
                    animate={featuresAnimation}
                    custom={0.6 + 0.1 * index}
                  />
                  <motion.p
                    variants={fadeInUpFixedVariants}
                    animate={featuresAnimation}
                    custom={0.6 + 0.1 * index}
                    className={classes["label"]}
                  >
                    {description}
                  </motion.p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div ref={downloadSectionRef}>
        <div className={classes["you-want-to"]} ref={youWantToAnimationRef}>
          <div className={classes["waves"]}>
            <motion.img
              src={wavesSvg}
              alt="waves"
              initial={{
                rotate: "0deg",
                scale: 3,
              }}
              animate={{
                rotate: "360deg",
                transition: {
                  ease: "linear",
                  duration: isSmallDevice ? 8 : 16,
                  repeat: Infinity,
                },
              }}
            />
          </div>
          <img
            src={
              isSmallerDevice
                ? R.images.default.ilYouWantToMobile
                : R.images.default.ilYouWantTo
            }
            alt="you-know"
            className={classes["you-know"]}
          />
          <img
            src={R.images.default.ilScreenshot}
            alt="screenshot 1"
            className={classes["il-screenshot"]}
          />

          <motion.img
            src={R.images.default.gifSmirkingFace}
            alt="emoji 1"
            className={classes["il-emoji-1"]}
            whileHover={shakeOnOver}
          />
          <motion.img
            src={R.images.default.gifFaceExhaling}
            alt="emoji 2"
            className={classes["il-emoji-2"]}
            whileHover={shakeOnOver}
          />
          <motion.img
            src={R.images.default.gifGhost}
            alt="ghost"
            className={classes["il-ghost"]}
            whileHover={shakeOnOver}
          />
          <motion.img
            src={R.images.default.gifFaceInClouds}
            alt="face clouds"
            className={classes["il-face-clouds"]}
            whileHover={shakeOnOver}
          />
          <motion.img
            src={R.images.default.gifHorns}
            alt="sign horns"
            className={classes["il-sign-horns"]}
            whileHover={shakeOnOver}
          />
          <motion.img
            src={R.images.default.gifEyes}
            alt="eyes 1"
            className={classes["il-eyes-1"]}
          />
          <motion.img
            src={R.images.default.gifEyes}
            alt="eyes 2"
            className={classes["il-eyes-2"]}
          />
          <div className={classes["buttons"]}>
            <a
              href={consumerPlayStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes["button"]}>
                <img src={R.images.default.icPlayStore} />
                <p>Google Play</p>
              </div>
            </a>
            <a
              href={consumerAppStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes["button"]}>
                <img src={R.images.default.icAppStore} />
                <p>App Store</p>
              </div>
            </a>
            {/* <div className={classes["wait"]}>
              <div className={classes["little-longer"]}>
                <img src={R.images.default.gifShushing} alt="shushing" />
                <p>
                  <span>just </span>a <span>little longer!</span>
                </p>
              </div>
              <p className={classes["follow"]}>
                Follow us on our socials in the mean time!
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes["email-section"]} ref={emailSectionAnimationRef}>
        <motion.div
          className={classes["container"]}
          variants={fadeInVariants}
          animate={emailSectionAnimation}
        >
          <motion.img
            src={R.images.default.gifEyes}
            alt="eyes"
            className={classes["il-eyes"]}
            variants={fadeInVariants}
            animate={emailSectionAnimation}
            custom={1.2}
          />
          <motion.img
            src={R.images.default.gifGhost}
            alt="ghost"
            className={classes["il-ghost"]}
            variants={fadeInVariants}
            animate={emailSectionAnimation}
            custom={1.2}
          />
          <motion.video
            className={classes["il-delivery"]}
            variants={fadeInLeftFixedVariants}
            animate={emailSectionAnimation}
            custom={1.3}
            autoPlay
            loop
            muted
            controls={false}
            src={isSafari ? deliverySafari : deliveryChrome}
          />
          <motion.p
            variants={fadeInUpVariants}
            animate={emailSectionAnimation}
            custom={0.5}
            className={classes["label"]}
          >
            Newsletter
          </motion.p>
          <motion.p
            variants={fadeInUpVariants}
            animate={emailSectionAnimation}
            custom={0.7}
            className={classes["title"]}
          >
            Be the first to hear about any promotions, news and a lot more.
          </motion.p>
          <motion.div
            className={classes["search-wrapper"]}
            variants={fadeInUpVariants}
            animate={emailSectionAnimation}
            custom={0.9}
          >
            <MailIcon width={24} height={24} color="#ffffff" />
            <input
              type="text"
              className={classes["search-input"]}
              placeholder="Enter your email.."
            />
            <button className={classes["button"]}>
              <ArrowRightIcon width={16} height={16} color={colors.primary} />
            </button>
          </motion.div>
        </motion.div>
        <motion.img
          src={R.images.default.ilLeftWave1}
          alt="Left wave"
          className={classes["il-left-wave-1"]}
          variants={fadeInLeftVariants}
          animate={emailSectionAnimation}
          custom={1}
        />
        <motion.img
          src={R.images.default.ilLeftWave2}
          alt="Left wave"
          className={classes["il-left-wave-2"]}
          variants={fadeInLeftVariants}
          animate={emailSectionAnimation}
          custom={1.1}
        />
        <motion.img
          src={R.images.default.ilRightWave1}
          alt="right wave"
          className={classes["il-right-wave-1"]}
          variants={fadeInRightVariants}
          animate={emailSectionAnimation}
          custom={1.1}
        />
        <motion.img
          src={R.images.default.ilRightWave2}
          alt="rightt wave"
          className={classes["il-right-wave-2"]}
          variants={fadeInRightVariants}
          animate={emailSectionAnimation}
          custom={1}
        />
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
